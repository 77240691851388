import { graphql } from "gatsby";
import Html2ReactParser from "html-to-react/lib/parser";
import React, { useEffect } from "react";
import Breadcrumbs from "../components/global/breadcrumbs";
import insertCTAs from "../components/global/ctas";
import Footer from "../components/global/footer";
import Navigation from "../components/global/navigation";
import SEO from "../components/global/seo";
import InternalNavWidget from "../components/widgets/internalNavWidget";

// CSS
import "../styles.css"

const GuideTemplate = ({ data }) => {
   useEffect(() => {
      window.scrollTo({ top: 0})
      console.log(data)
   }, [])

   // Variables
   const page = data.contentfulGuide;
   const image_url = page.heroImage.file.url
   const word_count = page.content.internal.content.split(" ").length + 1;
   const reading_time = (word_count/250).toFixed(0)
   const appliance = page.slug.split("/")[0]

   let image = "none"
   if(image_url !== '') {
      image = `url(${image_url})`
   }

   const generateContent = () => {
      const content = page.content.internal.content;
      const htmlToReactParser = new Html2ReactParser();
      const reactElement = htmlToReactParser.parse(content)

      const content_with_ctas = insertCTAs(reactElement, appliance)

      return content_with_ctas
   }


   return (
      <React.Fragment>
         <SEO
            title={page.title}
            slug={page.slug}
            metaDescription={page.metaDescription.internal.content}
            image=""
            titleTemplate="%s"
         />

         <Navigation/>

         <div className="content-hero-container guide" style={{ backgroundImage: image }}>
            <div className="content-hero-content-container guide">
               <h1>{page.title}</h1>
               <p>{page.articleDescription.internal.content}</p>
            </div>
         </div>

         <div className="page-details-container">
            <div className="page-details-inner-container wide-width">
               <p className="reading-time"><strong>Reading time</strong> - {reading_time} {reading_time === '1' ? 'minute' : 'minutes'}</p>
               <p className="reading-time"><strong>Author</strong> - {page.author}</p>
               <p className="last-updated"><strong>Last updated</strong> - {new Date(page.updatedAt).toLocaleDateString("en-GB")}</p>
            </div>
         </div>

         <div className="standard-container content">
            <div className="content-split-container wide-width">
               <div className="content-container">
                  <Breadcrumbs
                     location={page.slug}
                  />

                  {generateContent()}
               </div>
               <div className="side-bar-container">
                  <div className="side-bar-entries">
                     <InternalNavWidget navigation={page.navigation.internal.content}/>
                  </div>
               </div>
            </div>
         </div>


         <Footer/>
      </React.Fragment>
   )
}

export default GuideTemplate

export const pageQuery = graphql`
   query guideBySlug( $slug: String! ) {
      contentfulGuide ( slug: { eq: $slug }) {
         metaDescription {
            internal {
               content
            }
         }
         title
         slug
         updatedAt
         content {
            internal {
               content
            }
         }
         articleDescription {
            internal {
               content
            }
         }
         navigation {
            internal {
               content
            }
         }
         heroImage {
            file {
               url
            }
         }
         author
      }
   }
`