import React from "react"

export const cover_your_appliance_cta = (appliance) => {
   return (
      <div className="standard-cta-container">
         <span>
            <h3><span className="cta-highlight">Peace of mind</span> <span>for your {appliance === undefined || appliance === '' ? 'appliance' : appliance} 🤩</span></h3>
            <p>For as little as £6.50, you could cover your {appliance === undefined || appliance === '' ? 'appliance' : appliance} against breakdowns giving you peace of mind that if anything goes wrong, we'll be there.</p>
         </span>

         <a className="cta-button phone" href="#">0203&nbsp;953&nbsp;6001</a>
      </div>
   )
}

export const get_free_help_cta = (appliance) => {
   return (
      <div className="standard-cta-container secondary">
         <span>
            <h3><span className="cta-highlight">Get free help</span> <span>with your {appliance === undefined || appliance === '' ? 'appliance' : appliance} 💪</span></h3>
            <p>Call our expert engineering team who will walk you through the issues you're having with your {appliance === undefined || appliance === '' ? 'appliance' : appliance}, whether you have a plan or not.</p>
         </span>

         <a className="cta-button phone" href="#">0203&nbsp;953&nbsp;6001</a>
      </div>
   )
}

const insertCTAs = (content, category) => {
   let appliance = category
   if(category === 'tv') appliance = 'TV'

   let new_content = []

   for (let i = 0; i < content.length; i++) {
      const line = content[i];
      
      if(typeof line === 'string') {
         if(line.includes('[cover_your_appliance_cta]')) {
            new_content.push(cover_your_appliance_cta(appliance))
         } else if (line.includes('[get_free_help_cta]')) {
            new_content.push(get_free_help_cta(appliance))
         } else {
            new_content.push(line)
         }
      } else {
         new_content.push(line)
      }
   }

   return new_content
}

export default insertCTAs